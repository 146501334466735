import { useContext } from 'react'
import { useIntl } from 'react-intl'
import {
  AdGroupContext,
  AppContext,
  CampaignContext,
  EDAReportAudiencesColumnHeader,
  EDatasetKey,
  EDOOHReportLocationsColumnHeader,
  ESPReportProductsColumnHeader,
} from '../../../models'
import { TDatasetWhitelistKey } from '../models'
import { TGlobalTableDataType } from '../models/types/global-table-data.type'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { PORTAL_FF_REPORTING_METRICS_OMIT } from '../../../utils/constants'
import { EDAReportProductsColumnHeader } from '../../../models/core'

export const useGetDatasetKeyTableWhitelist = ({
  key,
}: {
  key: TDatasetWhitelistKey
}) => {
  const intl = useIntl()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const adGroup = useContext(AdGroupContext)
  const featureFlags = useFeatureFlags()

  const orgType = appContext.userOrg!.type

  const locationWhitelist = {} as Record<
    TDatasetWhitelistKey,
    Array<TGlobalTableDataType>
  >

  locationWhitelist[EDatasetKey.LocationDooh] = []
  locationWhitelist[EDatasetKey.AudienceDa] = []
  locationWhitelist[EDatasetKey.ProductSp] = []

  const whitelist: Record<TDatasetWhitelistKey, Array<TGlobalTableDataType>> = {
    'LOCATION-DOOH': [
      {
        header: EDOOHReportLocationsColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.locations`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.locations.tooltip`,
        }),
      },
      {
        header: EDOOHReportLocationsColumnHeader.Type,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Type
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Type
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportLocationsColumnHeader.AdgroupName,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.AdgroupName
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.AdgroupName
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportLocationsColumnHeader.Playouts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Playouts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Playouts
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportLocationsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportLocationsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Costs
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Costs
          }.tooltip`,
        }),
        isCurrency: true,
      },
      {
        header: EDOOHReportLocationsColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Cpm
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Cpm
          }.tooltip`,
        }),
        isCurrency: true,
      },
    ],
    'AUDIENCE-DA': [
      {
        header: EDAReportAudiencesColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.audiences`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.audiences.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Status,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Status
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Status
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.AdgroupName,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.AdgroupName
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.AdgroupName
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Placement,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Placement
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Placement
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Clicks
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Clicks
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Ctr
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Ctr
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.UniqueReach,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.UniqueReach
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.UniqueReach
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Frequency,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Frequency
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Frequency
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Costs
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Costs
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Cpm
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Cpm
          }.tooltip`,
        }),
      },
    ],
    'PRODUCT-SP': [
      {
        header: ESPReportProductsColumnHeader.Image,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Image
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Image
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.products`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.products.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Status,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportProductsColumnHeader.Status
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportProductsColumnHeader.Status
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Campaigns,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Campaigns
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Campaigns
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Adgroups,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Adgroups
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Adgroups
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Clicks
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Clicks
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Ctr
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Ctr
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Costs
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Costs
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Cpc,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Cpc
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Cpc
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.AvgRank,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.AvgRank
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.AvgRank
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Carts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Carts
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Purchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Purchases
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Revenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Revenue
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Roas
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Roas
          }.tooltip`,
        }),
      },
    ],
    'PRODUCT-DA': [
      {
        header: EDAReportProductsColumnHeader.Image,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Image
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Image
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.products`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.products.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Status,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportProductsColumnHeader.Status
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportProductsColumnHeader.Status
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Campaigns,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Campaigns
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Campaigns
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Adgroups,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Adgroups
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Adgroups
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Clicks
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Clicks
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Ctr
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Ctr
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.UniqueReach,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.UniqueReach
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.UniqueReach
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Frequency,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Frequency
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Frequency
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Costs
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Costs
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Cpm
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Cpm
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Roas
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Roas
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Purchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Purchases
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.PvPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvPurchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvPurchases
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Carts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Carts
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.PvCarts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvCarts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvCarts
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Revenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Revenue
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.PvRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvRevenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvRevenue
          }.tooltip`,
        }),
      },
    ],
  }

  locationWhitelist[key] = whitelist[key]

  if (adGroup.id && key === EDatasetKey.LocationDooh) {
    locationWhitelist[EDatasetKey.LocationDooh] = locationWhitelist[
      EDatasetKey.LocationDooh
    ].filter(
      (column) => column.header !== EDOOHReportLocationsColumnHeader.AdgroupName
    )
  }
  if (adGroup.id && key === EDatasetKey.AudienceDa) {
    locationWhitelist[EDatasetKey.AudienceDa] = locationWhitelist[
      EDatasetKey.AudienceDa
    ].filter(
      (column) => column.header !== EDOOHReportLocationsColumnHeader.AdgroupName
    )
  }
  if (campaign.id && key === EDatasetKey.ProductSp) {
    locationWhitelist[EDatasetKey.ProductSp] = locationWhitelist[
      EDatasetKey.ProductSp
    ].filter(
      (column) => column.header !== ESPReportProductsColumnHeader.Campaigns
    )
  }
  if (adGroup.id && key === EDatasetKey.ProductSp) {
    locationWhitelist[EDatasetKey.ProductSp] = locationWhitelist[
      EDatasetKey.ProductSp
    ].filter(
      (column) => column.header !== ESPReportProductsColumnHeader.Adgroups
    )
  }
  if ((!campaign.id || !adGroup.id) && key === EDatasetKey.ProductSp) {
    locationWhitelist[EDatasetKey.ProductSp] = locationWhitelist[
      EDatasetKey.ProductSp
    ].filter((column) => column.header !== ESPReportProductsColumnHeader.Status)
    locationWhitelist[EDatasetKey.ProductSp] = locationWhitelist[
      EDatasetKey.ProductSp
    ].filter(
      (column) => column.header !== ESPReportProductsColumnHeader.AvgRank
    )
  }
  if (campaign.id && key === EDatasetKey.ProductDa) {
    locationWhitelist[EDatasetKey.ProductDa] = locationWhitelist[
      EDatasetKey.ProductDa
    ].filter(
      (column) => column.header !== EDAReportProductsColumnHeader.Campaigns
    )
  }
  if (adGroup.id && key === EDatasetKey.ProductDa) {
    locationWhitelist[EDatasetKey.ProductDa] = locationWhitelist[
      EDatasetKey.ProductDa
    ].filter(
      (column) => column.header !== EDAReportProductsColumnHeader.Adgroups
    )
  }
  if ((!campaign.id || !adGroup.id) && key === EDatasetKey.ProductDa) {
    locationWhitelist[EDatasetKey.ProductDa] = locationWhitelist[
      EDatasetKey.ProductDa
    ].filter((column) => column.header !== ESPReportProductsColumnHeader.Status)
  }

  if (
    featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT] &&
    featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT].length
  ) {
    locationWhitelist[key] = locationWhitelist[key].filter(
      (column) =>
        !featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT].includes(column.header)
    )
  }

  return locationWhitelist[key]
}
