import { useContext } from 'react'
import { useIntl } from 'react-intl'
import {
  AppContext,
  EDAReportAudiencesColumnHeader,
  EDOOHReportLocationsColumnHeader,
  EReportingMetricNumberType,
  ESPReportProductsColumnHeader,
} from '../../../models'
import { TDatasetWhitelistKey, TGlobalMetricData } from '../models'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { PORTAL_FF_REPORTING_METRICS_OMIT } from '../../../utils/constants'
import { EDAReportProductsColumnHeader } from '../../../models/core'

export const useGetDatasetKeyChartWhitelist = ({
  key,
}: {
  key: TDatasetWhitelistKey
}) => {
  const intl = useIntl()

  const [appContext] = useContext(AppContext)
  const featureFlags = useFeatureFlags()

  const orgType = appContext.userOrg!.type

  let locationWhitelist: Record<
    TDatasetWhitelistKey,
    Array<TGlobalMetricData>
  > = { 'LOCATION-DOOH': [], 'AUDIENCE-DA': [], 'PRODUCT-SP': [] }

  const whitelist: Record<TDatasetWhitelistKey, Array<TGlobalMetricData>> = {
    'LOCATION-DOOH': [
      {
        metricName: EDOOHReportLocationsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Costs
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDOOHReportLocationsColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Cpm
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isCurrency: true,
      },
      {
        metricName: EDOOHReportLocationsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Impressions
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDOOHReportLocationsColumnHeader.Playouts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Playouts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
    ],
    'AUDIENCE-DA': [
      {
        metricName: EDAReportAudiencesColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Impressions
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportAudiencesColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Clicks
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportAudiencesColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Ctr
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: EDAReportAudiencesColumnHeader.UniqueReach,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.UniqueReach
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportAudiencesColumnHeader.Frequency,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Frequency
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportAudiencesColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Costs
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportAudiencesColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Cpm
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isCurrency: true,
      },
    ],
    'PRODUCT-SP': [
      {
        metricName: ESPReportProductsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Impressions
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportProductsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Clicks
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportProductsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Ctr
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: ESPReportProductsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Costs
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: ESPReportProductsColumnHeader.Cpc,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Cpc
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isCurrency: true,
      },
      {
        metricName: ESPReportProductsColumnHeader.AvgRank,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.AvgRank
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
      },
      {
        metricName: ESPReportProductsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Carts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportProductsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Purchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportProductsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Revenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: ESPReportProductsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Roas
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
    ],
    'PRODUCT-DA': [
      {
        metricName: EDAReportProductsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Impressions
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Clicks
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Ctr
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: EDAReportProductsColumnHeader.UniqueReach,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.UniqueReach
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.Frequency,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Frequency
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Costs
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportProductsColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Cpm
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isCurrency: true,
      },
      {
        metricName: EDAReportProductsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Roas
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Purchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.PvPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvPurchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Carts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.PvCarts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvCarts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Revenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportProductsColumnHeader.PvRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvRevenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
    ],
  }

  if (
    featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT] &&
    featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT].length
  ) {
    locationWhitelist[key] = whitelist[key].filter(
      (column) =>
        !featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT].includes(
          column.metricName
        )
    )
  } else {
    locationWhitelist = whitelist
  }

  return locationWhitelist[key]
}
